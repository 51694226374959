import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Admin from "./pages/Admin";
import Home from "./pages/landing/Home";
import FAQ from "./pages/landing/FAQ";
import Terms from "./pages/landing/Terms";
import Privacy from "./pages/landing/Privacy";
import EventDetails from "./pages/landing/EventDetails";

function App() {
  return (
    <div className="mx-auto">
      <div className="bg-gray-50 ">
        <Homepage />
      </div>
    </div>
  );
}

export default App;
