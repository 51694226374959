import React from "react";
import AccordianBtn from "./AccordianBtn";
import AccordianDetails from "./AccordianDetails";

function AccordianGroup(props) {
  const parent = props.parent;
  const heading = props.heading;
  const body = props.body;
  const idNum = props.idNum;

  return (
    <div className="accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray">
      <AccordianBtn idNum={idNum} heading={heading} />
      <AccordianDetails parent={parent} idNum={idNum} body={body} />
    </div>
  );
}

export default AccordianGroup;
