import React, { useState } from "react";
import Heading from "../../common/Heading";
import FormController from "../../controller/FormController";

function Registration() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
    dob: "",
    eduInstitution: "",
    eduLevel: "",
    recommendedBy: "",
  });
  const {
    name,
    email,
    address,
    phone,
    dob,
    eduInstitution,
    eduLevel,
    recommendedBy,
  } = formData;

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    FormController.newParticipant(formData);
  };

  return (
    <div id="registration">
      <Heading heading="Registration Form" />

      <div className="registrationForm  flex justify-center  pb-10 ">
        <form
          onSubmit={handleSubmit}
          className="mb-0 space-y-6 bg-white py-8 px-6 shadow rounded-lg lg:max-w-lg w-full sm:px-10 "
        >
          {/* // ! Name */}
          <div className="form-group">
            <label for="name" className="required-field ">
              Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                className=" w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500  transition
                ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="name"
                id="name"
                placeholder="Enter Your Name"
                required
                value={name}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* // ! Email */}
          <div className="form-group">
            <label for="email" className="required-field">
              Email Address
            </label>
            <div className="mt-1">
              <input
                type="email"
                className=" w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500  transition
                ease-in-out focus:text-gray-700 focus:bg-white focus:outline-none"
                name="email"
                id="email"
                placeholder="Enter Email Address"
                required
                value={email}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* // ! Address */}
          <div className="form-group">
            <label for="address" className="required-field">
              Address
            </label>
            <div className="mt-1">
              <input
                type="text"
                className=" w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500  transition
                ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="address"
                id="address"
                placeholder="Enter Your Address"
                required
                value={address}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* // ! Phone */}
          <div className="form-group">
            <label for="phone" className="required-field">
              Phone
            </label>
            <div className="mt-1">
              <input
                type="tel"
                name="phone"
                id="phone"
                placeholder="Enter Your phone"
                required
                value={phone}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* // ! DOB */}
          <div className="form-group">
            <label for="dob" className="required-field">
              Date of Birth
            </label>
            <div className="mt-1">
              <input
                type="date"
                name="dob"
                id="name"
                required
                value={dob}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* // ! Edu Institution */}
          <div className="form-group">
            <label for="eduInstitution" className="required-field">
              Educational Institution
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="eduInstitution"
                id="eduInstitution"
                placeholder="Enter Your Educational Institution"
                required
                value={eduInstitution}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* // ! Edu Level */}
          <div className="form-group">
            <label for="eduLevel" className="required-field">
              Education Level
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="eduLevel"
                id="eduLevel"
                placeholder="Enter Your Education Level"
                required
                value={eduLevel}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* // ! Recommended By */}
          <div className="form-group">
            <label for="recommendedBy">Recommended By</label>
            <div className="mt-1">
              <input
                type="text"
                name="recommendedBy"
                id="recommendedBy"
                placeholder="Recommended By"
                value={recommendedBy}
                onChange={handleChange}
              />
            </div>
          </div>

          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Registration;
