import React from "react";
import Heading from "../../common/Heading";

function Privacy() {
  return (
    <div className="bg-gradient-to-br from-[#87CF8E] to-[#b8CF87]" id="privacy">
      <Heading heading="Privacy Policy" />

      <div className="prose max-w-4xl text-lg mx-auto pb-10 px-5">
        <p>
          We at <strong>Sharda Production Pvt. Ltd.</strong> are committed to
          protect our client’s personal information and/or sensitive personal
          data and strive to maintain the privacy of your personal information.
          “Personal information” is any information that can be used by itself
          to uniquely identify, contact, or locate a person, or can be used with
          information available from other sources to uniquely identify an
          individual.
        </p>
        <p>
          <strong>Sharda Production Pvt. Ltd.</strong> does collect your
          personal information for a variety of regulatory and business
          purposes. These include, but are not limited to:
          <ul className="list-disc">
            <li>Respond to your request for service or assistance </li>
            <li>
              Perform market analysis, market research, business and operational
              analysis
            </li>
            <li>Provide, maintain and improve our products and services </li>
            <li>
              Promote and market our products and services which we consider may
              be of interest to you and ensure adherence to legal and regulatory
              requirements for prevention and detection of frauds and crimes.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
}

export default Privacy;
