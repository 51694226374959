import React from "react";
import AccordianGroup from "../../accordian/AccordianGroup";
import Heading from "../../common/Heading";
import "tw-elements";

function FAQ() {
  const accordianId = "FAQ-Accordion";
  return (
    <div id="faq">
      <Heading heading="Frequently Asked Questions" />
      <div className="max-w-xl mx-auto pb-10 text-lg">
        <div className="accordion" id={accordianId}>
          <AccordianGroup
            className="border-t-0"
            parent={accordianId}
            heading="Who can participate?"
            body="The participant should be a SEE pass out between the ages of 16 to 26 years old."
            idNum="One"
          />
          <AccordianGroup
            parent={accordianId}
            heading="How will I know if I am shortlisted after the audition?"
            body="If shortlisted, you will get a SMS on your registered number notifying you about the further details. Moreover, you can also check out the name list on our Facebook page of the shortlisted participants for the next round."
            idNum="Two"
          />
          <AccordianGroup
            parent={accordianId}
            heading=" How long should your speech be?"
            body="Participants must prepare a speech of 90 seconds to 120 seconds duration for the 1st audition round. They will be stopped at the end of 2 minutes."
            idNum="Three"
          />
          <AccordianGroup
            parent={accordianId}
            heading=" Is there any language barrier?"
            body=" Participants can give the speech in both Nepali & English language."
            idNum="Four"
          />
          <AccordianGroup
            parent={accordianId}
            heading="Are we allowed to choose topics to speak about?"
            body="Participants can give the speech in any topic of their choice."
            idNum="Five"
          />
          <AccordianGroup
            parent={accordianId}
            heading="Is it mandatory to be present during mentorship?"
            body="Mentorship session is scheduled to help candidates improvise and present better. It is optional and a candidate may choose to skip it, if she/he wants to. However, we highly recommend the participants to utilize the most out of these sessions."
            idNum="Six"
          />
          <AccordianGroup
            parent={accordianId}
            heading="How do I get Khalti Cash Back on my registration fee?"
            body="Fill up the form from your Khalti app, make the payment and get cash back instantly. However, if you have filled the forms from other platforms then, come and collect the Khalti cash back voucher from Khalti booth on your audition day."
            idNum="Seven"
          />
          <AccordianGroup
            parent={accordianId}
            heading="Where do I come for a physical registration form?"
            body="You can visit our central office for the event, Dursikshya Education Network Pvt. Ltd. Located at Jamal, Kathmandu."
            idNum="Eight"
          />
          <AccordianGroup
            parent={accordianId}
            heading="Where will the mentorship and audition rounds be held?"
            body="All the mentorship as well as audition rounds will be held at Academy of Culinary Arts, Lagankhel, Lalitpur."
            idNum="Nine"
          />
        </div>
      </div>
    </div>
  );
}

export default FAQ;
