import React from "react";

function AccordianDetails(props) {
  const idNum = props.idNum;
  const body = props.body;
  const parent = props.parent;
  return (
    <div
      id={"flush-collapse" + idNum}
      className="accordion-collapse border-0 collapse bg-[#cfdeaf] "
      aria-labelledby={"flush-heading" + idNum}
      data-bs-parent={parent}
    >
      <div className="accordion-body py-4 px-5 text-base">{body}</div>
    </div>
  );
}

export default AccordianDetails;
