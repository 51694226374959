import React from "react";
import Heading from "../../common/Heading";

function EventDetails() {
  return (
    <div id="eventDetails">
      <Heading className="flex justify-center" heading="Event Details" />
      <div className="prose max-w-4xl text-lg mx-auto pb-10 px-5">
        <p>
          Talking Titans - II allows youth the opportunity to express themselves
          through verbal communication. It emphasizes more on the youths as they
          are the ones who are honing their skills for the corporate world and
          public speaking happens to be one of the basic as well as a life skill
          for that matter.{" "}
        </p>
        <p>
          The event will be held by the end of Ashad. <br />
          <strong>
            <ul className="list-none">
              <li>
                Winner takes home the TITLE of the “Talking Titan” and the grand
                CASH PRIZE of NPR.100,000/-
              </li>
              <li>Popular Choice NPR.30,000/-</li>
              <li>Photo Contest Winner NPR.20,000/-</li>
            </ul>
          </strong>
        </p>
      </div>
    </div>
  );
}

export default EventDetails;
