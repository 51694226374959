import axios from "axios";

const API_URL = "/api/";

const newParticipant = async (formData) => {
  const response = await axios.post(API_URL, formData);

  return response.data;
};

const FormController = {
  newParticipant,
};

export default FormController;
