import React from "react";

function Footer() {
  return (
    <div className="bg-black">
      <div className="prose prose-lg mx-auto text-center text-white">
        &copy; Copyright - Talking Titans 2022
      </div>
    </div>
  );
}

export default Footer;
