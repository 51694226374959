import React from "react";
import NavbarPhone from "./NavbarPhone";

function Header() {
  return (
    <header
      id="home"
      className="w-full bg-no-repeat md:bg-[url('https://www.motif.com.np/images/ielts.png')] md:h-96 md:bg-cover"
    >
      <NavbarPhone />
      <img
        src="https://www.motif.com.np/images/ielts.png"
        alt=""
        srcSet=""
        className="md:hidden block w-full"
      />

      {/* <div className="prose prose-lg">
        <h1>Talking Titans II</h1>
      </div> */}
    </header>
  );
}

export default Header;
