import React from "react";

function Heading(props) {
  const heading = props.heading;
  return (
    <div className="flex justify-center pt-10">
      <div className="prose prose-base pb-10 text-center">
        <h1>{heading}</h1>
      </div>
    </div>
  );
}

export default Heading;
