import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../common/Header";
import Navbar from "../common/Navbar";
import NavbarPhone from "../common/NavbarPhone";
import EventDetails from "./landing/EventDetails";
import FAQ from "./landing/FAQ";
import Footer from "./landing/Footer";
import Home from "./landing/Home";
import Privacy from "./landing/Privacy";
import Registration from "./landing/Registration";
import Terms from "./landing/Terms";

function Homepage() {
  return (
    <>
      <div className="shadow-md ">
        {/* <div className="flex flex-col md:flex-col-reverse"> */}
        {/* <NavbarPhone /> */}
        <Header />
        <EventDetails />
        <Terms />
        <Registration />
        <Privacy />
        <FAQ />
        <Footer />
      </div>
      {/* <Navbar /> */}
      {/* <Outlet /> */}
      {/* </div> */}
    </>
  );
}

export default Homepage;
