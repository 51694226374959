import React from "react";

function AccordianBtn(props) {
  const idNum = props.idNum;
  const heading = props.heading;
  return (
    <h2 className="accordion-header mb-0" id={"flush-heading" + idNum}>
      <button
        className="accordion-button
        collapsed
    relative
    flex
    items-center
    w-full
    py-4
    px-5
    text-lg text-gray-800 text-left
    shadow-md
    bg-white
    border-0
    rounded-none
    transition
    focus:outline-none"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={"#flush-collapse" + idNum}
        aria-expanded="false"
        aria-controls={"flush-collapse" + idNum}
      >
        {heading}
      </button>
    </h2>
  );
}

export default AccordianBtn;
