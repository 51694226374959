import React from "react";
import Heading from "../../common/Heading";

function Terms() {
  return (
    <div className="bg-gradient-to-br from-[#87CF8E] to-[#b8CF87]" id="terms">
      <Heading heading="Terms and Conditions" />

      <p className="prose max-w-4xl text-lg mx-auto pb-10 px-5">
        <ol className="w-auto">
          <li>
            The participants must be a SEE pass out with an age in-between 16 –
            26 years old.
          </li>
          <li>
            The participants are required to give the speech in English or
            Nepali only.
          </li>
          <li>
            All the participating students will get a FREE mentorship session
            before the first round of audition.
          </li>
          <li>
            Students must prepare a short speech on a topic of their choice for
            the audition round.
          </li>
          <li>
            Shortlisted participants must choose a different topic for each
            round.
          </li>
          <li>
            Shortlisted participants of each round of auditions will get a FREE
            mentorship session on grooming their speaking skills.
          </li>
          <li>
            Top 15 finalists will compete for the title on 2nd July, 2022 (18th
            Ashad, 2079).
          </li>
          <li>
            All the participants of first audition round will also compete for
            Photo Contest Award through social media i.e. Facebook likes
          </li>
          <li>
            Selected Students from the first audition round will also compete
            for the Popular Choice Award through YouTube views.
          </li>
          <li>
            All the participants will be getting cash back of NPR.100/- on their
            registration fee through their Khalti account.
          </li>
        </ol>
      </p>
    </div>
  );
}

export default Terms;
